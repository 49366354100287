import React, { FunctionComponent, MouseEvent } from 'react';
import cx from 'classnames';
import ChevronS1Right from '../Icons/ChevronS1Right';
import ChevronS2Right from '../Icons/ChevronS2Right';
import Touchable, { TouchableProps } from '../Touchable';

import styles from './Button.module.scss';

export interface Props extends TouchableProps {
  animatedCaret?: boolean;
  large?: boolean;
  onClick?: (e: MouseEvent) => void;
  secondary?: boolean;
  small?: boolean;
  submitting?: boolean;
}

export const Button: FunctionComponent<Props> = ({
  animatedCaret,
  children,
  centered,
  className,
  disabled,
  inline,
  submitting,
  large,
  role,
  secondary,
  small,
  ...restProps
}) => (
  <Touchable
    block={!inline}
    className={cx(
      styles.button,
      disabled && styles.disabled,
      inline && styles.inline,
      large && styles.large,
      submitting && styles.submitting,
      secondary && styles.secondary,
      small && styles.small,
      centered && styles.centered,
      className,
    )}
    disabled={disabled || submitting}
    role={role ? role : 'button'}
    {...restProps}
  >
    <span className={cx(styles.flex)}>
      <span className={styles.text}>{children}</span>
      {!centered && (
        <span
          className={cx(styles.caret, animatedCaret && styles.animatedCaret)}
        >
          {small ? (
            <ChevronS2Right
              className={cx(styles.icon, animatedCaret && styles.secondaryIcon)}
            />
          ) : (
            <ChevronS1Right
              className={cx(styles.icon, animatedCaret && styles.secondaryIcon)}
            />
          )}
          {animatedCaret &&
            (small ? (
              <ChevronS2Right className={styles.icon} />
            ) : (
              <ChevronS1Right className={styles.icon} />
            ))}
        </span>
      )}
    </span>
  </Touchable>
);

// Button.defaultProps = { appearance: Appearance.PRIMARY };
Button.displayName = 'Button';
export default Button;
export { styles };
